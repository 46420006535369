import { useCallback, useMemo } from "react";
import { Bond, Inference, Side, useDataContext } from "@/app/data/dataProvider";
import { PriceType } from "@/types/types";
import { ProbabilityCalculationItem } from "@/utils/inference/calculateMaxProbability";
import { ProfitCalculationItem } from "@/utils/inference/calculateMaxProfit";
import { QuantilesData } from "@/app/run/components/runMaxProbabilityResultPreview";
import { roundToDecimals, roundToEighth } from "@/utils/number.utils";
import { getQuantileIndexV2 } from "@/app/data/data.utils";

export type DataItem = {
  current: number | undefined;
  currentString: string;
  currentTime: string | undefined;
  prev: number | undefined;
  prevString: string;
  prevTime: string | undefined;
  diff: number | undefined;
  diffString: string
  quantiles?: number[];
  prevQuantiles?: number[];
  tenor?: number;
  data?: {
    maxProbability?: number | string;
    error?: string;
    data?: ProbabilityCalculationItem[] | ProfitCalculationItem[] | QuantilesData | undefined
  };
};

export type DataFields = {
  price: DataItem;
  ytm: DataItem;
  gspread: DataItem;
}

export type InferenceResult<T extends Bond> = ReturnType<typeof useSimpleInferenceData<T>>

export function useSimpleInferenceData <T extends Bond>({ 
  items, 
  roundPrice,
  getInference,
  getQuantileIndex,
  getQuantileValues,
}: {
  items: T[];
  roundPrice?: boolean;
  getInference: (figi: string, side: Side, rfqLabel: PriceType, previous?: boolean) => Inference | undefined;
  getQuantileIndex?: (side: Side, priceType: PriceType, item: T) => number;
  getQuantileValues?: (data: {
    item: T, 
    side: Side, 
    currentQuantiles: number[], 
    prevQuantiles: number[], 
    priceType: PriceType, 
    quantileIndex: number,
  }) => {
    current: number | undefined;
    prev: number | undefined;
    data?: {
      maxProbability?: number | string;
      calcData?: ProbabilityCalculationItem[] | ProfitCalculationItem[] | QuantilesData | undefined
    }
  }
}) {
  const { bidPercentile, dealerPercentile,  offerPercentile} = useDataContext();

  const defaultGetQuantileIndex = useCallback((side: Side, priceType: PriceType) => {
    const percentile = side === Side.bid ? bidPercentile : side === Side.dealer ? dealerPercentile : offerPercentile;
    return getQuantileIndexV2(percentile, side, priceType)
  }, [bidPercentile, dealerPercentile, offerPercentile]);

  const _getQuantileIndex = getQuantileIndex || defaultGetQuantileIndex;

  const data = useMemo(() =>
    items.reduce((result, item) => {
      const itemData = [Side.bid, Side.dealer, Side.offer].reduce((a, side) => {
        
        function getDataItem(t: PriceType, decimals: number = 0, prefix: string = '', suffix: string = ''): DataItem {
          const quantileIndex = _getQuantileIndex(side, t, item)
          const currentInference = getInference(item.figi, side, t);
          const prevInference = getInference(item.figi, side, t, true);

          const currentTime = currentInference?.date.toISOString();
          const prevTime = prevInference?.date.toISOString();
          const currentQuantiles = currentInference?.[t] || [];
          const prevQuantiles = prevInference?.[t] || [];
          
          
          const { current, prev, data } = getQuantileValues 
            ? getQuantileValues({ item, side, currentQuantiles, prevQuantiles, priceType: t, quantileIndex }) 
            : {
              current: currentQuantiles[quantileIndex],
              prev: prevQuantiles[quantileIndex],
              data: undefined
            }
          const diff = current && prev ? current - prev : undefined;

          const formatStrValue = (v: string) => `${prefix}${v}${suffix}`;

          // calculate current value
          let roundedCurrent: undefined | number;
          let currentString: string;
          let currentDecimals = decimals;
          if (t === PriceType.GSpread) {
            currentDecimals = roundPrice ? 0 : 1;
            roundedCurrent = current !== undefined ? roundToDecimals(current, currentDecimals) : current;
            currentString = roundedCurrent !== undefined ? `${roundedCurrent}` : '-';
          } else if (t === PriceType.Ytm) {
            roundedCurrent = current !== undefined ? roundToDecimals(current, decimals) : current;
            currentString = roundedCurrent !== undefined ? formatStrValue(`${roundedCurrent}`) : '-';
          } else {
            roundedCurrent = current !== undefined && roundPrice ? roundToEighth(current) : current;
            currentString = roundedCurrent !== undefined ? formatStrValue(roundedCurrent.toFixed(currentDecimals)) : '-';
          }

          return {
            current: roundedCurrent,
            currentString,
            prev,
            prevString: prev !== undefined ? formatStrValue(prev.toFixed(currentDecimals)) : '-',
            diff,
            diffString: diff !== undefined 
              ? diff >= 0 
                ? formatStrValue(diff.toFixed(currentDecimals)) 
                : `(${formatStrValue(Math.abs(diff).toFixed(currentDecimals))})` 
              : '-',
            currentTime,
            prevTime,
            data,
            quantiles: currentQuantiles,
            prevQuantiles,
            tenor: currentInference?.tenor,
          }
        }

        a[side] = {
          price: getDataItem(PriceType.Price, 3, '$') as DataItem,
          ytm: getDataItem(PriceType.Ytm, 2, '', '%') as DataItem,
          gspread: getDataItem(PriceType.GSpread, 0) as DataItem,
        };
        return a;
      }, {} as { [key in Side]: DataFields });
      result[item.figi] = itemData;
      return result;
    }, {} as { [figi: string]: { [key in Side]: DataFields } }),
    [items, getInference, _getQuantileIndex, roundPrice]
  );

  const createDataSort = useMemo(() =>
    (side: Side, key: 'price' | 'ytm' | 'gspread', prop: 'current' | 'prev' | 'diff' | 'tenor') =>
      (a: T, b: T) => {
        const aVal = data[a.figi][side][key][prop];
        const bVal = data[b.figi][side][key][prop];
        return aVal === undefined && bVal !== undefined ? -1 :
          aVal !== undefined && bVal === undefined ? 1 :
            aVal === undefined && bVal === undefined ? 0 :
              aVal! - bVal!;
      }
    , [data]);

  return { data, createDataSort };
}

