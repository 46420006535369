import { Bond } from "@/app/data/bondIndex";
import { PortfolioResponse, Position } from "@/app/data/portfolios"
import { InferenceResult } from "@/hooks/data/useSimpleInferenceData";
import { convertRatingToSAndP, getSAndPNumericValue, numericFormatter } from "@/utils/number.utils";
import clsx from "clsx"
import { Fragment, ReactNode, useMemo } from "react"
import { getPositionValue, getPrevPositionValue } from "../portfolio.utils";
import { NAV_FORMAT, TWO_DECIMALS } from "@/constants";
import { Side } from "@/app/data/api";
import { isEmpty } from "lodash";
import { Tooltip } from "@/app/components/tooltip";
import { FaCircleInfo } from "react-icons/fa6";
import { SAndPRatingTooltipContent } from "./SAndPRatingTooltipContent";
import { EditPortfolioNAV } from "./EditPortfolioNAV";
import { useDataContext } from "@/app/data/dataProvider";

export const PortfolioSummary = ({
  portfolio,
  positions,
  inferenceData,
  isFetchingPortfolio,
  onNavChange,
}: {
  portfolio: PortfolioResponse | undefined;
  positions: (Position & Bond)[],
  inferenceData: InferenceResult<Position & Bond>['data'],
  isFetchingPortfolio?: boolean;
  onNavChange: (nav: number | undefined) => void;
}) => {
  const { getUnrecognizedFigis } = useDataContext();


  const { 
    avgPrice,
    avgPriceDelta,
    avgRating,
    shortMV,
    longMV,
    grossMV,
    deltaShortMV,
    deltaLongMV,
    deltaGrossMV,
    totalValue,
    totalDelta,
  } = useMemo(() => {
    let totalRating = 0;
    let totalRatedValue = 0; // when we calculate rating we ignore S&P 0 rating so total value will be different
    let totalValueAbs = 0;
    let prevTotalValueAbs = 0;
    let totalSize = 0;
    let longMV = 0;
    let shortMV = 0;
    let prevLongMV = 0;
    let prevShortMV = 0;
    let totalValue = 0;
    let totalDelta = 0;
    const unrecognizedFigisSet = new Set(getUnrecognizedFigis())


    positions.forEach((position) => {
      const positionValue = getPositionValue(position, inferenceData);

      // rating calculation
      const bondRating = getSAndPNumericValue(position.rating);
      if (bondRating !== 0) {
        // we calculate rating only for bonds with rating > 0
        totalRating += Math.abs(positionValue) * getSAndPNumericValue(position.rating);
        totalRatedValue += Math.abs(positionValue);
      }
      
      
      // we do not include unrecognized bonds in the calculation
      if (unrecognizedFigisSet.has(position.figi)) {
        return;
      }

      const prevPositionValue = getPrevPositionValue(position, inferenceData, false);

      if (position.size < 0) {
        shortMV += positionValue;
        prevShortMV += prevPositionValue;
      } else {
        longMV += positionValue;
        prevLongMV += prevPositionValue;
      }
      
      totalValueAbs += Math.abs(positionValue);
      prevTotalValueAbs += Math.abs(prevPositionValue);
      totalSize += Math.abs(position.size);
      totalValue += positionValue;
      totalDelta += positionValue - prevPositionValue // getPositionValue(position, inferenceData, true);
    })

    const avgRating = totalRatedValue === 0
      ? 0
      : totalRating / totalRatedValue;
    const avgPrice = totalSize === 0 
      ? 0
      : totalValueAbs / totalSize * 100;
    const prevAvgPrice = totalSize === 0
      ? 0
      : prevTotalValueAbs / totalSize * 100;
    const avgPriceDelta = avgPrice - prevAvgPrice;
    const grossMV = longMV - shortMV;
    const prevGrossMV = prevLongMV - prevShortMV;

    const deltaLongMV = longMV - prevLongMV;
    const deltaShortMV = shortMV - prevShortMV;
    const deltaGrossMV = grossMV - prevGrossMV;

    return {
      avgPrice: numericFormatter(avgPrice, TWO_DECIMALS),
      avgPriceDelta: numericFormatter(avgPriceDelta, TWO_DECIMALS),
      avgRating: convertRatingToSAndP(avgRating),
      shortMV: numericFormatter(shortMV, NAV_FORMAT),
      longMV: numericFormatter(longMV, NAV_FORMAT),
      grossMV: numericFormatter(grossMV, NAV_FORMAT),
      deltaShortMV: numericFormatter(deltaShortMV, NAV_FORMAT),
      deltaLongMV: numericFormatter(deltaLongMV, NAV_FORMAT),
      deltaGrossMV: numericFormatter(deltaGrossMV, NAV_FORMAT),
      totalValue: numericFormatter(totalValue, NAV_FORMAT),
      totalDelta: numericFormatter(totalDelta, NAV_FORMAT),
    }
  }, [positions, inferenceData, getUnrecognizedFigis])

  const hasInferenceData = useMemo(() => {
    // go through all positions and check if we have inference data for at least one of them 
    for (let i = 0; i < positions.length; i++) {
      if (!isEmpty(inferenceData[positions[i].figi].bid.price.quantiles)) {
        return true;
      }
    }

    return false;
  }, [positions, inferenceData])

  const hasPrevInferenceData = useMemo(() => {
    // go through all positions and check if we have inference data for at least one of them 
    for (let i = 0; i < positions.length; i++) {
      if (!isEmpty(inferenceData[positions[i].figi].bid.price.prevQuantiles)) {
        return true;
      }
    }

    return false;
  }, [positions, inferenceData])
  
  const bondsMissing = useMemo(() => {
    if (!hasInferenceData) {
      return 0;
    }

    const unrecognizedFigisSet = new Set(getUnrecognizedFigis());

    // go through all positions and check how many of them are present in unrecognizedFigisSet
    const bondsMissingCount = positions.reduce((acc, position) => {
      if (unrecognizedFigisSet.has(position.figi)) {
        return acc + 1;
      }
      return acc;
    }, 0);
    
    return bondsMissingCount;
  }, [positions, hasInferenceData, getUnrecognizedFigis])



  const bondsTotal = positions.length;
  const bondsPriced = bondsTotal - bondsMissing;

  const topRow = [
    { title: 'NAV', value: <EditPortfolioNAV nav={portfolio?.value?.nav} onNavChange={onNavChange} isFetchingPortfolio={isFetchingPortfolio} />   },
    {
      title: 'Deep MM NAV',
      value: dataOrPlaceholer(totalValue),
      subValue: `Δ ${prevDataOrPlaceholder(totalDelta)}`
    },
    {
      title: 'Deep MM Long MV',
      value: dataOrPlaceholer(longMV),
      subValue: `Δ ${prevDataOrPlaceholder(deltaLongMV)}`
    },
    {
      title: 'Deep MM Short MV',
      value: dataOrPlaceholer(shortMV),
      subValue: `Δ ${prevDataOrPlaceholder(deltaShortMV)}`
    },
    {
      title: 'Deep MM Gross MV',
      value: dataOrPlaceholer(grossMV),
      // subValue: `Δ ${deltaGrossMV}`
    }
  ]

  function dataOrPlaceholer(value: number | string) {
    if (!hasInferenceData) {
      return '-';
    }
    return value;
  }

  function prevDataOrPlaceholder(value: number | string) {
    if (!hasPrevInferenceData) {
      return '-';
    }
    return value;
  }

  const bottomRow = [
    {
      title: 'Bond Count',
      value: bondsTotal,
    },
    {
      title: 'Bond Missing',
      value: dataOrPlaceholer(bondsMissing),
    },
    {
      title: 'Priced Bonds',
      value: dataOrPlaceholer(bondsPriced),
    },
    {
      title: 'Avg. Price',
      value: dataOrPlaceholer(avgPrice),
    },
    {
      title: 'Avg. Price Δ',
      value: dataOrPlaceholer(avgPriceDelta),
    },
    // {
    //   title: 'Avg. Spread',
    //   value: 1.00, // 1 decimal
    // },
    // {
    //   title: 'Avg. Spread Δ',
    //   value: '-', // 1 decimal
    // },
    // {
    //   title: 'Avg. Yield',
    //   value: '1.00', // 3 decimal
    // },
    // {
    //   title: 'Avg. Yield Δ',
    //   value: '-', // 3 decimal
    // },
    {
      title: (
        <>
          Avg. Rating
          <Tooltip tooltip={<SAndPRatingTooltipContent />} maxWidthCss='max-w-[20.625rem]' delayShow={350}>
            <FaCircleInfo className="cursor-pointer hover:text-[#FBFBFD] "  />
          </Tooltip>
        </>
      ),
      value: dataOrPlaceholer(avgRating),
    },
  ]

  return (
    <div className="flex flex-col gap-[0.625rem]">
      {/* top row */}
      <Row items={topRow} textCss="text-[1.875rem] leading-[2.1875rem]" />

      {/* bottom row */}
      <Row items={bottomRow} textCss='text-[1.25rem] leading-[1.4375rem]' itemCss='md:pt-[6px] pt-0' gridOnMobile />
    </div>
  )
}

// Helper components

// Row
const Row = ({
  items,
  textCss,
  itemCss,
  gridOnMobile,
}: {
  textCss: string;
  itemCss?: string;
  gridOnMobile?: boolean;
  items: {
    title: ReactNode,
    value: string | number | undefined | ReactNode
    subValue?: string | number | undefined | ReactNode
  }[]
}) => {

  const rowStyles = clsx(' min-h-[3.8125rem] flex-wrap ', {
    'grid grid-cols-[1fr,1fr] sm:grid-cols-[1fr,1fr,1fr,1fr] gap-x-[2rem] gap-y-[0.625rem] md:gap-[0.625rem]  md:flex': gridOnMobile,
    'flex flex-col md:flex-row gap-[0.625rem]': !gridOnMobile,
  })

  return (
    <div className={rowStyles}>
      {items.map((item, index) => {
        return (
          <Fragment key={index}>
            {/* item */}
            <Item className={itemCss}>
              <Title>
                {item.title}
              </Title>
              <div className={clsx(textCss, 'text-[#FBFBFD] font-medium')}>
                {item.value}
              </div>
              {typeof item.subValue !== 'undefined' && (
                <div className="md:text-right">
                  {item.subValue}
                </div>
              )}
            </Item>
            {/* separator */}
            {index !== items.length - 1 && <Separator />}
          </Fragment>
        )
      })}
    </div>
  )
}

// Item
const Item = ({
  children,
  className,
}: {
  children: ReactNode
  className?: string;
}) => {
  return <div className={className}>{children}</div>
}


// Title
const Title = ({ children }: {
  children: ReactNode
}) => {
  return (
    <h5 className="text-[#C9CADE] text-[0.875rem] leading-[1rem] font-medium whitespace-nowrap items-end flex gap-1">
      {children}
    </h5>
  )
}

// Separator
const Separator = () => {
  return <div className="w-[1px] h-[15px] bg-[#5D5F9D] self-center hidden md:block" />
}